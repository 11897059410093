import { useState } from "react";
import { Link } from "react-router-dom";
import Text from "../components/Text";
import { useStore } from "../utils/Store";

const Page = {
    Header: () => {
        const [store, setStore] = useStore();

        const menuList = [
            {
                path: '/',
                name: 'Home'
            },
            {
                path: '/about',
                name: 'About'
            },
            {
                path: '/services',
                name: 'Services'
            },
            {
                path: '/contact',
                name: 'Contact Us'
            }
        ];


        function toggleTrigger() {
            setStore({ ...store, showMobMenu: !store.showMobMenu })
        }

        const view = <div className="page-header">
            <div className="page-header_wrapper _container">
                <div className="page-header_logo">
                    <div className="brand-logo">Speed Wind</div>
                </div>
                <div className="page-header-menu">
                    {menuList.map(item =>
                        <div className="page-header-menu_item">
                            <Link to={item.path}>{item.name}</Link>
                        </div>
                    )}
                </div>
                <div className="page-header-menu-mobile">
                    <div className="page-header-menu-mobile_trigger" onClick={toggleTrigger}>
                        <i class="fas fa-bars"></i>
                    </div>
                    {store?.showMobMenu ? <div className="page-header-menu-mobile-menu">
                        <div className="page-header-menu-mobile_trigger _close" onClick={toggleTrigger}>
                            <i class="far fa-times-circle"></i>
                        </div>
                        {menuList.map(item =>
                            <div className="page-header-menu-mobile_item">
                                <Link to={item.path}>{item.name}</Link>
                            </div>
                        )}
                    </div> : ''}
                </div>
            </div>
        </div>

        return view;
    },
    Footer: () => {

        const view = <div className="page-footer">
            <div className="_container">
                <div className="page-footer-data columns">
                    <div className="column col-4  col-sm-12 page-footer-data-item">
                        We wish to continue get your business and trust by rendering professional services while honesty, integrity and hard work that this company was founded on.
                    </div>
                    <div className="column col-4 col-sm-12 page-footer-data-item">
                        <Text.H2 label="Our Service" />
                        <div className="page-footer-data-item-box">
                            <div className="page-footer-data-item-box-item">
                                <span>Renovation & Maintaince</span>
                            </div>
                            <div className="page-footer-data-item-box-item">
                                <span>Cleaning & Pest Control</span>
                            </div>
                            <div className="page-footer-data-item-box-item">
                                <span>Construction</span>
                            </div>
                        </div>
                    </div>
                    <div className="column col-4  col-sm-12 page-footer-data-item">
                        <Text.H2 label="Contact Us" />
                        <div className="page-footer-data-item-box">
                            <div className="page-footer-data-item-box-item">
                                <i class="fas fa-map-marker-alt icon"></i>
                                <span>Prince Nawaf St. - Cross 22 - Building No.8064, Saad Al-Qathani office Complex Building</span>
                            </div>
                            <div className="page-footer-data-item-box-item">
                                <i class="fas fa-phone-alt icon"></i>
                                <span>0563220473/0551125508</span>
                            </div>
                            <div className="page-footer-data-item-box-item">
                                <i class="fas fa-envelope icon"></i>
                                <span>service@speedwindcontracting.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-footer-bar">
                <div className="_container">Copyright © Speed Wind Contracting Est . all rights reserved.</div>
            </div>
        </div>;
        return view;
    }
}

export default Page;