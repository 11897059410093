import Button from '../components/Button';
import './Home.scss';

import IntroImg from '../assets/background/about-img.jpg';
import VideoImg from '../assets/background/video-img.png';
import GridImg1 from '../assets/background/ser1.jpg';
import GalImg1 from '../assets/background/project6.jpg';

import Thumb1 from '../assets/thumbs/sh3.png';
import Emblm from '../assets/thumbs/embl.png';


import Text from '../components/Text';

const Home = {
    Index: () => {
        return <div className="page-home_container">
            <div className="page-home-jumbotron">
                <div className="page-home-jumbotron_wrapper _container">
                    <div className="page-home-jumbotron_body">
                        <div className="page-home-jumbotron_title">
                            For Safe and Healthy Living
                        </div>
                        <div className="page-home-jumbotron_description">
                            Pest Control Services in Saudi Arabia
                        </div>
                        <Button.Secondary label="Get Started" />
                    </div>
                </div>
            </div>
            <Home.QuickPick />
            <Home.Intro />
            <Home.Services />
            <Home.Gallery />
        </div>
    },
    QuickPick: () => {
        const list = [
            {
                thumb: Thumb1,
                name: 'Renovation & Maintaince',
                description: 'We are work closely with clients to gain an understanding of your our desires both philoso.'
            },
            {
                thumb: Thumb1,
                name: 'Cleaning &  Pest Control',
                description: 'We are work closely with clients to gain an understanding of your our desires both philoso.'
            },
            {
                thumb: Thumb1,
                name: 'Construction Design',
                description: 'We are work closely with clients to gain an understanding of your our desires both philoso.'
            }
        ];

        const view = <div className="page-home-quickpick _container columns">
            {list.map(item => <div className="page-home-quickpick-item  column col-4">
                <div className="page-home-quickpick-item_wrapper">
                    <div className="page-home-quickpick-item_img">
                        <img src={item.thumb} alt={item.name} />
                    </div>
                    <div className="page-home-quickpick-item_name">
                        <Text.H2 label={item.name} />
                    </div>
                    <div className="page-home-quickpick-item_description">
                        {item.description}
                    </div>
                </div>
            </div>)}
        </div>;
        return view;
    },
    Intro: () => {
        const view = <div className="page-home-intro _container columns">
            <div className="page-home-intro_info column col-5 col-sm-12">
                <div className="page-home-intro_title">
                    <Text.H3 label="Why is Pest Control required in Homes and Commercial Spaces?" />
                </div>
                <div className="page-home-intro_description">
                    Pests can cause serious annoyance harming health and assets. Cockroaches, wood borers, ants, bedbugs, rats, and flies can be hazardous for all living spaces.
                    <br /><br />
                    While some of these pests are can affect health since they contaminate surfaces and food, other pests directly damage furniture, clothes and even electrical appliances.
                    <br /><br />
                    Since most of these pests are resilient and procreate rapidly, pest control at regular intervals is a necessity.

                </div>
                <Button.Outline label="More About Us" />
            </div>
            <div className=" column col-7  col-sm-12">
                <img src={IntroImg} alt="our services" />
            </div>
            <div className="column col-12" style={{ textAlign: 'center', paddingTop: '50px' }}>
                <div className="columns" style={{ justifyContent: 'center' }}>
                    <div className="column col-5 col-sm-12">
                        <img src={Emblm} style={{ maxWidth: '100%' }} />
                    </div>
                </div>
            </div>
        </div>;

        return view;
    },
    Banner: () => {
        const view = <div className="page-home-banner">
            <div className="_container">
                <img src={VideoImg} alt="video img" />
            </div>
        </div>;

        return view;
    },
    Services: () => {

        const list = [
            {
                name: 'Maintenance Services',
                description: 'Our success relies on our ability to find, motivate and retain a team of positive folk who  are committed to delivering outstanding service for maintenance and repair work/project.',
                img: Thumb1,
                preview: GridImg1
            },
            {
                name: 'Cleaning Services',
                description: 'Interior cleaning services for Commercial Malls, Offices, Organizations, Apartments including hospitality services i.e. Cleaners. The right of all our staff to work in the Saudi Arabia is critical to our business and to our customers peace of mind, thoroughly screen all our candidates very seriously.',
                img: Thumb1,
                preview: GridImg1
            },
            {
                name: 'Deep Cleaning Services',
                description: 'Air treatment, cleaning and sanitizing services. interior and exterior deep cleaning services and yearly contract. Various types of maintenance, which include immediate, periodic and emergency services in order to fulfill your needs in a comprehensive, specialized and safe way',
                img: Thumb1,
                preview: GridImg1
            }
        ];

        const view =
            <div className="_container">
                <div className="page-home-services">
                    <div className="page-home-services-header">
                        <div className="page-home-services-header_title">
                            <Text.H2 label="Our Services" />
                        </div>
                        <div className="page-home-services-header_description">
                            <Text.H3 label="Interested in Our Services" />
                        </div>
                    </div>
                    <div className="page-home-services-body columns">
                        {
                            list.map((item, key) => <div className="page-home-services-body-item column col-4 col-sm-12 col-md-6" key={key}>
                                <div className="page-home-services-body-item_wrapper">
                                    <div>
                                        <img className="thumb" src={item.img} alt={item.name} />
                                        <div className="page-home-services-body-item_label"><Text.H2 label={item.name} /></div>
                                        <div className="page-home-services-body-item_text">{item.description}</div>
                                    </div>
                                    <div className="page-home-services-body-item_preview">
                                        <img src={item.preview} />
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>;

        return view;
    },
    Gallery: () => {
        const view = <div className="page-home-gallery  _container">
            <div className="columns">
                <div className="page-home-gallery-thumb column col-6  col-sm-12">
                    <img src={GalImg1} alt="gallery" />
                </div>
                <div className="column col-6  col-sm-12">
                    <div className="page-home-gallery-text"><Text.H3 label="We Provide the Interior Design" /></div>
                    <div className="page-home-gallery-description">
                        Studio practice focused on modern design, interiors and landscapes. From our inception in 1990, we have livered exceptional public and private.
                    </div>
                    <div className="page-home-gallery-list">
                        <strong><div>1.Quality Architect Designs</div>
                            <div>2.100% Satisfaction Guarantee</div>
                            <div>3.Highly Professional Members</div>
                            <div>4.Deliver Always on Time</div>
                        </strong>
                    </div>
                    <Button.Outline label="See Details" />
                </div>
            </div>
        </div>

        return view;
    }
}

export default Home;