import Text from "../components/Text";
import Home from "../home/Home";
import './About.scss';

import Client1 from '../assets/thumbs/client-1.png';
import Client2 from '../assets/thumbs/client-2.png';
import Client3 from '../assets/thumbs/client-3.png';
import Client4 from '../assets/thumbs/client-4.png';

const About = {
    Index: () => {
        const clientlist = [
            Client3, Client4, Client1, Client2
        ];

        const view = <div className="page-about-wrapper">
            <div className="page-about-container _container">
                <About.Bio />
                <Home.Intro />
            </div>
            <div className="page-about-stats-container">
                <div className=" _container">
                    <div className="page-about-stats columns">
                        <div className="column col-3 col-sm-6 page-about-stats_count">
                            <div className="stat-label">
                                <Text.H3 label="87 +" />
                                Happy Customers
                            </div>
                        </div>
                        <div className="column col-3 col-sm-6 page-about-stats_count">
                            <div className="stat-label">
                                <Text.H3 label="187 +" />
                            </div>
                            Completed Works
                        </div>
                        <div className="column col-3 col-sm-6 page-about-stats_count">
                            <div className="stat-label">
                                <Text.H3 label="50 +" />
                            </div>
                            Team Members
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-about-clients _container">
                <Text.H3 label="Our Clients" />
                <div className="page-about-clients-list columns">
                    {
                        clientlist.map((item, key) => <div key={key} className="page-about-clients-item column col-2 col-sm-3">
                            <img className="invert" src={item} />
                        </div>)
                    }
                </div>
            </div>
        </div >;

        return view;
    },
    Bio: () => {
        const view = <div className="page-about-intro">
            <Text.H3 label="Who we are" />
            <div className="columns page-about-intro-wrap">
                <div className="column col-6 col-sm-10 page-about-intro-description">
                    We are pleased to introduce our company Speed wind constracting EST is established on 1990. Al-Khobar We provide support servises, Construction, Renovation, Maintaince, Cleaning, Pest control services. We maintaining quality and our aim to satisfy our customer with quality assured service.
                </div>
            </div>
        </div>;

        return view;
    }
}


export default About;