import { useEffect } from "react";
import {
    Switch,
    Route,
    useLocation,
    Redirect
} from "react-router-dom";
import About from "./about/About";
import Contact from "./contact/Contact";
import Home from "./home/Home";
import Services from "./services/Services";
import { useStore } from "./utils/Store";

const routesList = [
    {
        path: "/",
        exact: true,
        component: Home.Index
    },
    {
        path: "/contact",
        exact: true,
        component: Contact.Index
    },
    {
        path: "/about",
        exact: true,
        component: About.Index
    },
    {
        path: "/services",
        exact: true,
        component: Services.Index
    }];


export const Routes = () => {
    const location = useLocation();
    const [store, setStore] = useStore();
    useEffect(() => {
        window.scrollTo(0, 0);
        setStore({ ...store, showMobMenu: false });
    }, [location.pathname]);

    return (
        <Switch location={location}>
            {routesList.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={({ location }) =>
                        <route.component />
                    }
                />

            ))}
            <Route path="*">
                {/* <page404 /> */}
            </Route>
        </Switch>
    );
}

export default { Routes };