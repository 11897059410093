import Text from '../components/Text';
import './Contact.scss';


const Contact = {
    Index: () => {

        const list = [
            {
                icon: <i class="fas fa-map-marker-alt"></i>,
                title: 'Office Location',
                description: [
                    '8114 - King Khalid St. Near to Al Turki Tower - Dammam',
                    'Prince Nawaf St. - Cross 22 - Building No.8064, Saad Al-Qathani office Complex Building'
                ]
            },
            {
                icon: <i class="fas fa-phone-alt"></i>,
                title: 'Phone Number',
                description: ['Tel: 8931633', 'Mob: 0563220473/0551125508']
            },
            {
                icon: <i class="far fa-envelope"></i>,
                title: 'E-mail',
                description: ['service@speedwindcontracting.com']
            }
        ];

        const view = <div className="page-contact-wrapper">
            <div className="page-contact-banner">
                <div className="text"> <Text.H3 label="Contact Us" /></div>
            </div>
            <div className="page-contact-list _container">
                <div className="columns col-gapless">
                    {list.map((item, key) => <div className="column col-4 col-sm-12" key={key}>
                        <div className="page-contact-list-item-wrapper">
                            <div className="page-contact-list-item-box">
                                <div className="page-contact-list-item-box-icon">
                                    {item.icon}
                                </div>
                                <div className="page-contact-list-item-box-title"><Text.H2 label={item.title} /></div>
                                {item.description.map((itemDescription, key2) => <div key={key2} className="page-contact-list-item-box-description">{itemDescription}</div>)}
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
            <div className="page-contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.0082984225037!2d50.208147215002406!3d26.29383099278732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e84769e89d53%3A0xa46d79ae38d8bb33!2sPrince%20Nawaf%20St%2C%20Al%20Khobar%20Al%20Shamalia%2C%20Al%20Khobar%20Saudi%20Arabia!5e0!3m2!1sen!2sin!4v1635958027183!5m2!1sen!2sin" className="map" height="450" style={{ border: 0, width: '100%' }} allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>;


        return view;
    }
}


export default Contact;