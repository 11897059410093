import './_config.scss';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './router';
import Page from './layouts/page';

function App() {
  return (
    <div className="App">
      <Router>
        <Page.Header />
        <Routes />
        <Page.Footer />
      </Router>
    </div>
  );
}

export default App;
