import './Components.scss';

const Button = {
    Primary: ({ label, ...rest }) => {
        const view = <div className="ui-button _primary">
            {label}
        </div>;
        return view;
    },
    Secondary: ({ label, ...rest }) => {
        const view = <div className="ui-button _secondary">
            {label}
        </div>;
        return view;
    },
    Outline: ({ label, ...rest }) => {
        const view = <div className="ui-button _outline">
            {label}
        </div>;
        return view;
    }
}

export default Button;